<template>
  <div>
    <div class="bg-gray-800" :class="{ 'pb-32': userHasTeams }">
      <nav class="bg-gray-800">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div :class="{ 'border-b border-gray-700': userHasTeams }">
            <div class="flex items-center justify-between h-16 px-4 sm:px-0">
              <div class="flex items-center flex-1 md:flex-none mr-4 md:mr-0">
                <div class="flex-shrink-0">
                  <img
                    v-if="!userHasTeams"
                    class="h-8 w-auto"
                    src="@/assets/icon-text-cloudsprouter-logo.png"
                    alt="Workflow logo"
                  />
                  <img
                    v-if="userHasTeams"
                    class="h-8 w-auto"
                    src="@/assets/icon-cloudsprouter-logo.png"
                    alt="Workflow logo"
                  />
                </div>
                <div
                  class="ml-6 flex items-baseline space-x-4 w-full md:w-auto"
                  v-if="userHasTeams"
                >
                  <TeamSelectorDropdown
                    :currentTeam="currentTeam"
                    :currentTeamId="currentTeamId"
                    :teams="teams"
                    @selectedTeam="selectedTeam"
                  />
                </div>
              </div>
              <div class="hidden md:block">
                <div class="ml-4 flex items-center md:ml-6">
                  <button
                    class="p-1 border-2 border-transparent rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 text-gray-400 mr-2"
                    aria-label="Notifications"
                    v-on:click="toggleUploadPopver"
                    v-if="userHasTeams"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      ></path>
                    </svg>
                  </button>

                  <button
                    class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700 relative"
                    aria-label="Notifications"
                  >
                    <svg
                      class="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                    <div
                      class="w-2 h-2 bg-red-600 rounded-full absolute top-0 right-0 mt-1 mr-1"
                    ></div>
                  </button>

                  <button
                    class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
                    aria-label="Support"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                  </button>

                  <div class="ml-4 relative flex-shrink-0">
                    <div>
                      <button
                        type="button"
                        class="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100 select-none"
                        id="user-menu"
                        aria-expanded="false"
                        aria-haspopup="true"
                        @click="navDropdownOpen = !navDropdownOpen"
                      >
                        <span class="sr-only">Open user menu</span>
                        <AccountIcon
                          :initials="userData.name | initials"
                          :size="8"
                        />
                      </button>
                    </div>

                    <transition>
                      <div
                        class="z-20 fixed inset-0"
                        aria-hidden="true"
                        v-if="navDropdownOpen"
                        @click.self="navDropdownOpen = !navDropdownOpen"
                      ></div>
                      <!-- bg-black bg-opacity-25 -->
                    </transition>

                    <transition
                      leave-active-class="transition ease-in duration-75"
                      leave-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <div
                        class="origin-top-right z-40 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="user-menu"
                        v-if="navDropdownOpen"
                        @click="navDropdownOpen = !navDropdownOpen"
                      >
                        <router-link
                          v-if="
                            userData.temp_role && userData.temp_role == 'admin'
                          "
                          :to="{ name: 'AdminHome' }"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >Admin</router-link
                        >
                        <router-link
                          :to="{ name: 'AppUserSettings' }"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >Settings</router-link
                        >
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          v-on:click="logout()"
                          >Sign out</a
                        >
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="-mr-2 flex md:hidden">
                <!-- Mobile menu button -->
                <button
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                >
                  <!-- Menu open: "hidden", Menu closed: "block" -->
                  <svg
                    class="block h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <!-- Menu open: "block", Menu closed: "hidden" -->
                  <svg
                    class="hidden h-6 w-6"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden border-b border-gray-700 md:hidden">
          <div class="px-2 py-3 space-y-1 sm:px-3">
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
              >Dashboard</a
            >
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Team</a
            >
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Projects</a
            >
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Calendar</a
            >
            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >Reports</a
            >
          </div>
          <div class="pt-4 pb-3 border-t border-gray-700">
            <div class="flex items-center px-5 space-x-3">
              <div class="flex-shrink-0">
                <!-- <img
                  class="h-10 w-10 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                /> -->
              </div>
              <div class="space-y-1">
                <div class="text-base font-medium leading-none text-white">
                  Tom Cook
                </div>
                <div class="text-sm font-medium leading-none text-gray-400">
                  tom@example.com
                </div>
              </div>
            </div>
            <div
              class="mt-3 px-2 space-y-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <a
                href="#"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
                >Your Profile</a
              >
              <a
                href="#"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
                >Settings</a
              >
              <a
                href="#"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
                >Sign out</a
              >
            </div>
          </div>
        </div>

        <div
          class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-6 space-x-2"
          v-if="userHasTeams"
        >
          <router-link
            :to="{ name: 'AppHome' }"
            class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 focus:outline-none focus:text-white focus:bg-gray-700"
            active-class="text-white bg-gray-900"
          >
            Dashboard
          </router-link>
          <router-link
            :to="{ name: 'AppVideos' }"
            class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            active-class="bg-gray-900 text-white"
          >
            Videos
          </router-link>
          <router-link
            :to="{ name: 'AppLive' }"
            class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            active-class="bg-gray-900 text-white"
          >
            Live
          </router-link>
          <router-link
            :to="{ name: 'AppSettings' }"
            class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            active-class="bg-gray-900 text-white"
          >
            Settings
          </router-link>
        </div>
      </nav>
    </div>

    <main :class="{ '-mt-32': userHasTeams }">
      <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
        <router-view></router-view>
      </div>
    </main>

    <footer>
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden">
        <p class="text-center text-base leading-6 text-gray-500">
          &copy; 2020-21 CloudSprouter. All rights reserved.
        </p>
      </div>
    </footer>
    <UploadPopover
      :isOpen="showUploadPopover"
      @closePopover="toggleUploadPopver"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import axios from "axios";

import UploadPopover from "@/screens/App/General/UploadPopover";
import TeamSelectorDropdown from "@/screens/App/General/TeamSelectorDropdown";
import AccountIcon from "@/components/AccountIcon";

export default {
  name: "AppLayout",
  components: {
    UploadPopover,
    TeamSelectorDropdown,
    AccountIcon,
  },
  data() {
    return {
      showUploadPopover: false,
      menuOpen: false,
      navDropdownOpen: false,
    };
  },
  computed: {
    ...mapState("auth", {
      teams: (state) => state.teams,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
    }),
    ...mapGetters({
      userHasTeams: "auth/userHasTeams",
    }),
    ...mapGetters("auth", ["userData"]),
  },
  async mounted() {
    axios.interceptors.response.use(null, (error) => {
      console.log(error);
      if (
        (error.response && error.response.status == 401) ||
        error.response.status == 419
      ) {
        // router.push({ path: "/auth/login" });
        console.log(router.currentRoute);
      }
      return Promise.reject(error);
    });
    await this.getTeamDetails(this.teams[0].id);
  },
  methods: {
    ...mapActions({
      getTeamDetails: "team/getTeamDetails",
      performLogout: "auth/performLogout",
    }),
    toggleUploadPopver() {
      this.showUploadPopover = !this.showUploadPopover;
    },
    async logout() {
      let res = await this.performLogout();
      console.log(res);
      this.$router.push({ name: "AuthLogin" });
    },
    async selectedTeam(id) {
      await this.getTeamDetails(id);
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
