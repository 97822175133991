<template>
  <div class="relative w-full">
    <div class="fixed inset-0 overflow-hidden" v-if="expanded">
      <div
        class="absolute inset-0 overflow-hidden"
        @click.self="toggleExpand"
      ></div>
    </div>
    <span class="inline-block w-full rounded-lg" style="min-width:200px">
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="bg-gray-700 text-white pl-4 pr-10 py-2 flex flex-row focus:outline-none font-semibold relative w-full rounded-lg transition ease-in-out duration-150 w-full"
        v-on:click="toggleExpand"
      >
        <TextLoader
          :loading="currentTeam == null"
          color="gray-800"
          width="32"
          class="w-full md:w-auto"
        >
          <span class="block truncate flex w-full">
            <span v-if="currentTeam !== null">{{ currentTeam }}</span>
          </span>
        </TextLoader>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
        >
          <svg
            class="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="none"
            stroke="currentColor"
          >
            <path
              d="M7 7l3-3 3 3m0 6l-3 3-3-3"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
    </span>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50"
        v-if="expanded"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
        >
          <li
            id="listbox-item-0"
            role="option"
            class="text-gray-900 cursor-pointer select-none relative py-2 pl-8 pr-4 hover:bg-gray-200"
            v-for="team in teams"
            v-bind:key="team.id"
            v-on:click="selectedTeam(team.id)"
          >
            <span class="font-normal block truncate">
              {{ team.name }}
            </span>
            <span
              class="absolute inset-y-0 left-0 flex items-center pl-1.5"
              v-if="team.id === currentTeamId"
            >
              <svg
                class="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
          <li class="border-t border-gray-200 my-1"></li>
          <li
            id="listbox-item-0"
            role="option"
            class="text-gray-900 cursor-pointer select-none relative py-2 pl-8 pr-4 hover:bg-gray-200"
          >
            <span class="font-normal block truncate">
              Create new team
            </span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import TextLoader from "@/screens/App/General/Loaders/TextLoader";
export default {
  name: "TeamSelector",
  props: ["teams", "currentTeam", "currentTeamId"],
  components: { TextLoader },
  data() {
    return {
      expanded: false,
    };
  },
  mounted() {
    console.log(this.teams);
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    selectedTeam(id) {
      this.$emit("selectedTeam", id);
    },
  },
};
</script>
