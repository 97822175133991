<template>
  <div>
    <span
      class="inline-flex items-center justify-center rounded-full bg-gray-500"
      :class="['w-' + size.toString(), 'h-' + size.toString()]"
    >
      <span class="leading-none text-white" :class="[getFontSize()]">{{
        initials
      }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    initials: {
      type: String,
      default: "NA",
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    getFontSize() {
      if (this.size == 8) {
        return "text-sm";
      }
      if (this.size == 10) {
        return "text-medium";
      }
      if (this.size == 20) {
        return "text-4xl";
      }
      return "text-lg";
    },
  },
};
</script>
